import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/beer-factory-blog/beer-factory-blog/src/templates/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`C'est parti pour une nouvelle aventure. Aujourd'hui je vous propose de vous lancer avec moi dans le monde merveilleur de la culture de levure.
Les article`}</p>
    <h1>{`Conclusion`}</h1>
    <p>{`Vous l'aurez compris je ne vous recommande pas forcément ce produit.`}</p>
    <p>{`Le principe est intéresant mais n'est pas suffisamment qualitatif pour y mettre le prix demandé. Pour le moment mon exemplaire est toujours fonctionnel et me donne encore satisfaction mais pour combien de brassin encore ?`}</p>
    <p>{`Leur second produit, le platoo Keg semble souffrir des même type de défaut raison pour laquelle je n'ai pas craqué (et que l'intégraton n'est pas disponible sur `}<a parentName="p" {...{
        "href": "https://mybeerfactory.fr"
      }}>{`My Beer Factory`}</a>{`)
Espérons que la jeune entreprise norvégienne nous propose une seconde version plus qualitative.`}</p>
    <p>{`N'hésitez pas à me faire part de vos remarques, idées d'amélioration ou simplement à partager vos créations en commentaire ou sur le `}<a parentName="p" {...{
        "href": "https://www.facebook.com/beerFactoryApp"
      }}>{`groupe facebook`}</a>{`. Cet article étant le premier je suis entrée dans les détails mais au fur et à mesure j'ai prévu de rester plus succinct qu'en pensez vous ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      